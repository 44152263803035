export default [
  {
    "key": "biome",
    "labelKey": "biome",
    "color": "#EB2F06",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "Olson et al."
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2001"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-public/initiatives/chaco/collection_5/layers/BIOMES.zip",
    "index": 1
  },
  {
    "key": "country",
    "labelKey": "country",
    "color": "#b024e0",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "Ajustado en base a IGN, 2018; Ajustado en base a IGM, 2022; DGEEC, 2012"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "IGN-2018, IGM-2022, DGEEC-2012"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-public/initiatives/chaco/collection_5/layers/POLITICAL_LEVEL_1.zip",
    "index": 2
  },
  {
    "key": "state",
    "labelKey": "state",
    "color": "#FBEC00",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "Ajustado en base a IGN, 2018; Ajustado en base a IGM, 2022; DGEEC, 2012"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "IGN-2018, IGM-2022, DGEEC-2012"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-public/initiatives/chaco/collection_5/layers/POLITICAL_LEVEL_2.zip",
    "index": 3
  },
  {
    "key": "municipality",
    "labelKey": "municipality",
    "color": "#FDBF6F",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "Ajustado en base a IGN, 2018; Ajustado en base a IGM, 2022; DGEEC, 2012"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "IGN-2018, IGM-2022, DGEEC-2012"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-public/initiatives/chaco/collection_5/layers/POLITICAL_LEVEL_3.zip",
    "index": 4
  },
  {
    "key": "protected_areas",
    "labelKey": "protected_areas",
    "color": "#31f211",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "WDPA, 2022, SERNAP, 2018"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2022"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-public/initiatives/chaco/collection_5/layers/PROTECTED_AREA.zip",
    "index": 5
  },
  {
    "key": "ramsar_site",
    "labelKey": "ramsar_site",
    "color": "#16dff2",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "WWF"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2013"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-public/initiatives/chaco/collection_5/layers/RAMSAR_SITES.zip",
    "index": 6
  }
];
